import { LOG, catAuth, catMain, catNav, catVue } from "@/util/logging";
import { MandantModel, UserDataModel } from "./models/UserDataModel";

import App from "./App.vue";
import MyJSON from "./util/json";
import { Route } from "vue-router";
import Vue from "vue";
import { api } from "@/util/api";
import router from "./router";
import store from "./store";
import { sync } from "vuex-router-sync";
import vuetify from "./plugins/vuetify";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).LOG = LOG;

catMain.info(
  `Initial Message. Version = ${process.env.VUE_APP_VERSION} (${process.env.VUE_APP_GIT_HASH}) in ${process.env.NODE_ENV}`
);
catMain.warn(
  ` Second Message. Version = ${process.env.VUE_APP_VERSION} (${process.env.VUE_APP_GIT_HASH}) in ${process.env.NODE_ENV}`
);
catMain.info(`Environment = ${process.env} ` + JSON.stringify(process.env));
// catMain.info(`VUEX = ${MyJSON.out(sessionStorage.getItem("vuex"))}`);

Vue.config.productionTip = false;

catVue.info("syncing store and router");
sync(store, router);
// see -> https://stackoverflow.com/questions/44171210/what-is-vuex-router-sync-for

catVue.info("setting permission mask");
sessionStorage.setItem("perm_mask", "0");

catVue.info("setting up vue");
const vue = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
});

export default vue;

// Will return true if there needs to be Authentication first
function validateAuthRoute(route: Route): boolean {
  //  catNav.info(`asked about Validation for ${MyJSON.out(route)}`);
  let loginFirst = true;
  try {
    const user = vue["$store"].getters["auth/loggedInUser"];
    //    catNav.info(`... with user ${user}`);
    //    catNav.info(`... from ${MyJSON.out(vue["$store"])}`);

    if ("" !== user && undefined !== user) {
      // Re-Validate Session
      store.dispatch("auth/validate", {}); // This runs asynchonous. But never mind, if we are unauthenticated, the interceptor will push to login.

      // Re-Verify the Permission Mask
      if (
        route.meta &&
        (0 == route.meta.permissionMask ||
          ((user as UserDataModel).Permissions & route.meta.permissionMask) !=
            0)
      ) {
        // catVue.info("next … OK")
        loginFirst = false;
      }
    }
  } catch (error) {
    catAuth.error("ERROR accessing permission getter: " + error, null);
  }

  return loginFirst;
}

router.beforeEach((to, from, next) => {
  let loginFirst = false;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    loginFirst = validateAuthRoute(to);
  }

  catNav.info(
    "NAVIGATION FROM " +
      from.fullPath +
      " => " +
      to.fullPath +
      " :: LoginFirst=" +
      loginFirst
  );
  if (loginFirst) {
    next({ path: "/login", query: { redirect: to.fullPath } });
    return;
  }

  if (to.meta && "mandantFeature" in to.meta) {
    const what = ":" + to.meta.mandantFeature;
    const mdt = vue["$store"].state.auth.mandant as MandantModel;

    catNav.warn("Checking " + what + " => " + mdt.features);
    if (!mdt.features.includes(what)) {
      next({ path: "/" });
      return;
    }
  }

  doFavicon(to);
  next(); // make sure to always call next()!
});

function doFavicon(to: Route) {
  let favicon = "favicon";
  if (to.meta && to.meta.favicon) favicon = to.meta.favicon;

  const link = (document.querySelector("link[rel*='shortcut icon']") ||
    document.createElement("link")) as HTMLLinkElement;
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = "/" + favicon + ".ico";
  document.getElementsByTagName("head")[0].appendChild(link);
}

catVue.info("mounting vue");
vue.$mount("#app");

router.onReady(() => {
  catNav.info("STARTUP: in onReady = " + MyJSON.out(router.currentRoute));

  doFavicon(router.currentRoute);

  const user = vue["$store"].getters["auth/loggedInUser"];
  if ("" !== user && undefined !== user && user.TOKEN) {
    catAuth.info("Retaining User Token from Store ... " + user.TOKEN);
    api.setToken(user.TOKEN);
  }

  const matchedComponents = router.getMatchedComponents();
  if (!matchedComponents.length) {
    catVue.error("STARTUP: No matched components => pushing '/'!", null);
    router.push("/");
    return;
  }

  if (router.currentRoute.meta && router.currentRoute.meta.requiresAuth) {
    if (validateAuthRoute(router.currentRoute)) {
      catVue.warn(
        "STARTUP: Force Login prior to path " + router.currentRoute.fullPath
      );
      router.push({
        path: "/login",
        query: { redirect: router.currentRoute.fullPath },
      });
    }
  }

  if (
    router.currentRoute.meta &&
    "mandantFeature" in router.currentRoute.meta
  ) {
    const what = ":" + router.currentRoute.meta.mandantFeature;
    const mdt = vue["$store"].state.auth.mandant as MandantModel;

    catNav.info("OnReady checking " + what + " => " + mdt.features);
    if (!mdt.features.includes(what)) {
      catNav.warn("Disallowed route for mandant: " + JSON.stringify(mdt));
      router.push({ path: "/" });
      return;
    }
  }
});

/* import hyphenateISBN from '@/util/isbn.ts'
console.log("ISBN: Test", hyphenateISBN("9781617295942"))
console.log("ISBN: Test", hyphenateISBN("9781617294624"))
console.log("ISBN: Test", hyphenateISBN("9781527268319"))
console.log("ISBN: Test", hyphenateISBN("9783836240031"))
console.log("ISBN: Test", hyphenateISBN("9781449326333"))
console.log("ISBN: Test", hyphenateISBN("0735619670")) */
