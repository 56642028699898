var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[(_vm.showFrame)?_c('v-navigation-drawer',{attrs:{"app":"","color":"indigo lighten-5"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('side-navigation')],1):_vm._e(),(_vm.showFrame)?_c('v-app-bar',{attrs:{"elevate-on-scroll":"","app":"","color":"indigo darken-4","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_c('v-img',{staticClass:"hidden-xs-only",attrs:{"height":"1.2em","width":"8em","aspect-ratio":"4.8","src":require('@/assets/prometheus-Logo.svg'),"alt":"TS-metheus"}}),_c('v-img',{staticClass:"hidden-sm-and-up",attrs:{"height":"1.2em","width":"2.5em","aspect-ratio":"1.5","src":require('@/assets/p8s-Logo.svg'),"alt":"TS-metheus"}})],1),_c('v-spacer'),(_vm.showFilialenPicker)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.auth.theFiliale !== null)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo lighten-1"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.auth.theFiliale.f_bez)+" "),_c('v-icon',[_vm._v("mdi-dots-vertical")])],1):_vm._e()]}}],null,false,3645697101)},[_c('v-list',{attrs:{"rounded":""}},[_c('v-subheader',[_vm._v("Filialen")]),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedFiliale),callback:function ($$v) {_vm.selectedFiliale=$$v},expression:"selectedFiliale"}},_vm._l((_vm.filialenList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.f_num)+": "+_vm._s(item.f_bez))])],1)}),1)],1)],1):_vm._e()],1):_vm._e(),_c('v-main',[_c('router-view')],1),(_vm.showFrame)?_c('v-footer',{attrs:{"color":"indigo darken-4","app":""}},[_c('span',{staticClass:"white--text"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.version))])]}}],null,false,645851112)},[_c('span',[_vm._v("Frontend:"+_vm._s(_vm.versionTip)+" | Backend:"+_vm._s(_vm.auth.apiVersion))])]),_vm._v(" © "+_vm._s(new Date().getFullYear())+" Thomas Ganter "),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(" — "),_c('a',{staticClass:"white--text",attrs:{"href":"//ganter.bayern","target":"_blank"}},[_vm._v("GANTER GmbH")])])],1),_c('v-spacer'),(_vm.store.state.isAppLoading)?_c('self-building-square-spinner',{attrs:{"animation-duration":6000,"size":22,"color":"white"}}):_vm._e(),(_vm.store.state.isAppLoading)?_c('span',{staticClass:"white--text",staticStyle:{"padding-left":"1ex"}},[_vm._v(_vm._s(_vm.store.state.percentComplete))]):_vm._e(),_c('v-spacer'),(_vm.loggedIn && _vm.auth.user !== null)?_c('span',{staticClass:"white--text"},[(_vm.auth.mandant !== null)?_c('span',{staticClass:"hidden-md-and-down"},[_vm._v(_vm._s(_vm.auth.mandant.Name)+" | ")]):_vm._e(),_vm._v(_vm._s(_vm.auth.user.Name)),(_vm.auth.geoName !== null)?_c('span',[_vm._v(" : "+_vm._s(_vm.auth.geoName))]):_vm._e(),_c('v-btn',{staticClass:"ma-2 hidden-sm-and-down",attrs:{"small":"","color":"indigo lighten-1","dark":""},on:{"click":_vm.auth.logout}},[_vm._v("Logout "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v("mdi-logout-variant")])],1),_c('v-btn',{staticClass:"ma-2 hidden-md-and-up",attrs:{"small":"","color":"indigo lighten-1","dark":""},on:{"click":_vm.auth.logout}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-logout-variant")])],1)],1):_c('span',{staticClass:"white--text"},[_vm._v("Niemand angemeldet. "),_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","color":"indigo lighten-1","dark":"","to":"/login"}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-login-variant")]),_vm._v(" Login ")],1)],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"elevation":"15"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Schließen ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),(_vm.app_ver_changed)?_c('v-snackbar',{attrs:{"timeout":-1,"value":true,"vertical":true,"color":"warning","absolute":"","right":"","rounded":"pill","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"indigo","text":""},on:{"click":function($event){return _vm.reload()}}},'v-btn',attrs,false),[_vm._v(" Neu laden. ")])]}}],null,false,51113771)},[_vm._v(" Version hat sich geändert. ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }