import { PermissionType } from "@/models/UserDataModel";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

export interface RouteMeta {
  icon: string | "";
  requiresAuth: boolean | true;
  hideAuthenticated: boolean | false;
  permissionMask: number | 0;
  hidden?: boolean | false;
  navpath?: string | null;
  needsFiliale?: boolean | string;
  mandantFeature?: string;
  favicon?: string;
}

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    meta: { icon: "mdi-login", requiresAuth: false, hideAuthenticated: true },
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "buecher" */ "../views/Login.vue"),
  },
  {
    meta: {
      icon: "mdi-home",
      requiresAuth: true,
      permissionMask: 0,
    } as RouteMeta,
    path: "/",
    name: "Home",
    component: Home,
  },

  // Bücher …
  {
    meta: {
      icon: "mdi-book",
      requiresAuth: true,
      permissionMask:
        PermissionType.PERM_BUCH_USERS | PermissionType.PERM_THOMAS,
    },
    path: "/buecher",
    name: "Bücher",
    component: () =>
      import(/* webpackChunkName: "buecher" */ "../views/Bücher.vue"),
    children: [
      {
        meta: {
          icon: "mdi-book-multiple",
          requiresAuth: true,
          permissionMask:
            PermissionType.PERM_BUCH_USERS |
            PermissionType.PERM_BUCH_OLA |
            PermissionType.PERM_THOMAS,
        },
        path: "listen",
        name: "Buchlisten",
        component: () =>
          import(
            /* webpackChunkName: "buch-listen" */ "@/components/bücher/Listen.vue"
          ),
      },
      {
        meta: {
          icon: "mdi-book-check",
          requiresAuth: true,
          permissionMask:
            PermissionType.PERM_BUCH_USERS |
            PermissionType.PERM_BUCH_OLA |
            PermissionType.PERM_THOMAS,
        },
        path: "bestellungen",
        name: "Buchbestellungen",
        component: () =>
          import(
            /* webpackChunkName: "buch-bestell" */ "@/components/bücher/Bestellungen.vue"
          ),
      },
    ],
  },

  // Zeitschriften …
  {
    meta: {
      icon: "mdi-newspaper-variant-outline",
      requiresAuth: true,
      permissionMask:
        PermissionType.PERM_BUCH_USERS | PermissionType.PERM_THOMAS,
    },
    path: "/zeitschriften",
    name: "Presse",
    component: () =>
      import(
        /* webpackChunkName: "zeitschriften" */ "../views/Zeitschriften.vue"
      ),
    children: [
      {
        meta: {
          icon: "fa-regular fa-file-invoice-dollar",
          //          icon: "mdi-receipt",
          requiresAuth: true,
          permissionMask:
            PermissionType.PERM_BABU_CREATE | PermissionType.PERM_THOMAS,
        },
        path: "rechnungen",
        name: "Rechnungen",
        component: () =>
          import(
            /* webpackChunkName: "presse-rechnungen" */ "@/components/rechnungen/PresseRechnungen.vue"
          ),
      },
    ],
  },

  // Regalkontrolle
  {
    meta: {
      icon: "mdi-bookshelf",
      requiresAuth: true,
      permissionMask: PermissionType.PERM_BABU | PermissionType.PERM_BUCH_USERS,
      navpath: "/regalkontrolle/",
      needsFiliale: true,
    },
    path: "/regalkontrolle/:produkt",
    name: "Regalkontrolle",
    component: () =>
      import(
        /* webpackChunkName: "regalkontrolle" */ "../views/Regalkontrolle.vue"
      ),
  },
  {
    meta: {
      requiresAuth: true,
      permissionMask: PermissionType.PERM_BABU | PermissionType.PERM_BUCH_USERS,
      hidden: true,
      needsFiliale: true,
    },
    path: "/regalkontrolle/",
    name: "Regalkontrolle-Plain",
    component: () =>
      import(
        /* webpackChunkName: "regalkontrolle" */ "../views/Regalkontrolle.vue"
      ),
  },
  {
    meta: {
      requiresAuth: true,
      permissionMask: PermissionType.PERM_BABU | PermissionType.PERM_BUCH_USERS,
      hidden: true,
      needsFiliale: true,
    },
    path: "/regalkontrolle",
    name: "Regalkontrolle-Noslash",
    component: () =>
      import(
        /* webpackChunkName: "regalkontrolle" */ "../views/Regalkontrolle.vue"
      ),
  },

  // {
  //   meta: { icon: 'mdi-information', requiresAuth: true, permissionMask: PermissionType.PERM_BABU },
  //   path: '/about/babu',
  //   name: 'Babu',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  // {
  //   meta: { icon: 'mdi-home-group', requiresAuth: true, permissionMask: PermissionType.PERM_BABU_CREATE },
  //   path: '/about/babu/create',
  //   name: 'Babu Create',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },

  {
    meta: {
      icon: "mdi-restart-alert",
      requiresAuth: true,
      permissionMask: PermissionType.PERM_BABU_CREATE,
      mandantFeature: "VCA",
    },
    path: "/importe/vca-fehler/",
    name: "VCA Fehlerliste",
    component: () =>
      import(/* webpackChunkName: "vca-fehler" */ "../views/Fehlerlisten.vue"),
  },

  {
    meta: {
      icon: "mdi-table-search",
      requiresAuth: true,
      permissionMask: PermissionType.PERM_SUPERUSER,
    },
    path: "/statistik",
    name: "Statistiken",
    component: () =>
      import(/* webpackChunkName: "statistiken" */ "../views/Statistiken.vue"),
    children: [
      {
        meta: {
          icon: "mdi-book-clock-outline",
          requiresAuth: true,
          permissionMask:
            PermissionType.PERM_SUPERUSER | PermissionType.PERM_BUCH_ADMINS,
        },
        // BuchBewegungen will be rendered inside User's <router-view>
        path: "buch-bewegungen",
        name: "Buchverkäufe",
        component: () =>
          import(
            /* webpackChunkName: "buchverlagsverkaeufe" */ "@/components/statistik/BuchVerlagsVerkaeufe.vue"
          ),
      },
      {
        meta: {
          icon: "mdi-table-headers-eye",
          requiresAuth: true,
          permissionMask:
            PermissionType.PERM_SUPERUSER | PermissionType.PERM_BABU_CREATE,
        },
        // BuchBewegungen will be rendered inside User's <router-view>
        path: "wf-monitor",
        name: "Mengensteuerung",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "wfmonitor" */ "@/components/statistik/BuchungsMonitor.vue"
          ),
      },
      {
        meta: {
          icon: "mdi-cash-register",
          requiresAuth: true,
          permissionMask:
            PermissionType.PERM_SUPERUSER | PermissionType.PERM_BABU_CREATE,
        },
        // BuchBewegungen will be rendered inside User's <router-view>
        path: "kassencheck",
        name: "Kassencheck",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "kassencheck" */ "@/components/statistik/Kassencheck.vue"
          ),
      },
    ],
  },

  {
    meta: {
      icon: "mdi-barcode-scan",
      requiresAuth: false,
      favicon: "buchscanner",
    },
    path: "/buchscanner",
    name: "Buch Scanner",
    component: () =>
      import(/* webpackChunkName: "buchscanner" */ "../views/Buchscanner.vue"),
  },

  {
    meta: { icon: "mdi-information", requiresAuth: false },
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
