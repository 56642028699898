
















































































































































































































import { catApi, catVue } from "@/util/logging";

import { FilialeModel } from "./models/FilialeModel";
import { SelfBuildingSquareSpinner } from "epic-spinners";
import Vue from "vue";
import axios from "axios";
import store from "@/store";

import AuthModule from "@/store/modules/auth";

import { getModule } from "vuex-module-decorators";

// eslint-disable-next-line

// import { LogLevel } from "typescript-logging";

export default Vue.extend({
  name: "ts-metheus",

  components: {
    SelfBuildingSquareSpinner,
    SideNavigation: () =>
      import(
        /* webpackChunkName: "app-side-navigation" */ "@/components/_app/SideNavigation.vue"
      ),
  },

  props: {
    source: String,
  },

  data: () => ({
    auth: getModule(AuthModule, store),
    store: store,

    drawer: false,
    //
    showFrame: true,
    //
    snackbar: false,
    text: "",
    timeout: 4500,

    filialenList: [] as FilialeModel[],
    filialenState: false,

    selectedFiliale: 0,

    app_ver_changed: false,
  }),

  methods: {
    reload() {
      window.location.reload();
    },

    byebye(): void {
      this.auth.logout();
    },

    get_app_ver: function () {
      if (this.app_ver_changed) return;

      axios
        .get("/gui.ver")
        .then((response) => {
          if (response.data !== process.env.VUE_APP_GIT_SEMVER) {
            console.log(
              `${process.env.VUE_APP_GIT_SEMVER} <-> ${response.data}`
            );
            this.app_ver_changed = true;
          }
        })
        .catch((error) => {
          console.log("In KO ... " + error);
        });
    },
  },

  computed: {
    showFilialenPicker(): boolean {
      if (!(this.$route.meta && "needsFiliale" in this.$route.meta))
        return false;

      return this.$route.meta.needsFiliale;
    },

    version(): string {
      return `${process.env.VUE_APP_VERSION}`;
    },

    versionTip(): string {
      return `${process.env.VUE_APP_GIT_SEMVER}`;
    },

    loggedIn(): boolean {
      return this.auth.loggedIn;
    },

    lastError(): string {
      return store.state.lastError;
    },

    userName(): string {
      console.log(this.auth);
      console.log(this.auth.user);
      if (!this.auth.user) return "(niemand)";
      return this.auth.user.Name;
    },
  },

  watch: {
    selectedFiliale(newValue: number, oldValue: number) {
      if (newValue == oldValue) return;

      if (newValue >= this.filialenList.length) {
        catVue.error("Filiale selected outside List Size", null);
        return;
      }

      const selectedFiliale = this.filialenList[newValue];
      store.commit("auth/setFiliale", selectedFiliale);
    },

    showFilialenPicker(newValue: boolean, oldValue: boolean) {
      catVue.info(
        "showFilialenPicker changed?   " + newValue + ":<-" + oldValue
      );

      if (newValue == oldValue) return;

      if (newValue == this.filialenState) return this.filialenList;

      this.filialenState = newValue;
      this.filialenList = new Array<FilialeModel>();

      if (this.filialenState) {
        let fsel = -1;
        const fnum = this.auth.theFiliale?.f_num;
        this.$store.state.auth.filialLst.forEach((f: FilialeModel) => {
          if (f.f_state == "GROSSO" || f.f_state == "DIREKT") {
            if (f.f_num == fnum) fsel = this.filialenList.length;
            this.filialenList.push(f);
          }
        });

        if (fsel == -1) {
          catVue.info("fsel still -1 ... ");
        }

        this.selectedFiliale = fsel;
      }

      return this.filialenList;
    },

    lastError(newValue, oldValue) {
      catApi.info("Change on API error: from " + oldValue + " -> " + newValue);

      if (newValue !== "") {
        this.text = newValue;
        this.snackbar = true;
      }
    },

    snackbar(newValue, oldValue) {
      catApi.info("Change on snackbar: from " + oldValue + " -> " + newValue);

      store.commit("setLastError", "");
    },
  },
  mounted() {
    catVue.info("Locating ... App.vue ... " + this.auth.user?.Name);
    console.log(this.auth);
    this.auth.locate();

    // in Development Mode this is done by yarn-serve-magic
    //
    if (process.env.NODE_ENV != "development") {
      this.get_app_ver();
      setInterval(this.get_app_ver, 15001);
    }
  },
});
