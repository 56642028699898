<template>
   <v-container class="fill-height" fluid>
      <v-row class="text-center">
         <v-col cols="12">
            <h1>Prometheus-App V2</h1>
            <h2>Bitte eine Funktion aus dem Menü wählen</h2>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
   // @ is an alias to /src
   export default {
      name: "Home",
      components: {},
   };
</script>
