import { catMain } from "@/util/logging";
import { plainToClassFromExist } from "class-transformer";

export class BaseType {
  uid = ""; //  MUST be extended by subtype

  static createInstance<B extends BaseType>(c: new () => B): B {
    return new c();
  }

  static idFromURL(url: string): undefined | number {
    if ("" === url) return undefined;

    const regex = /\/.*\/(?<id>\d+)/;

    catMain.info(`url:${url} -> match?:${url.match(regex)}`);
    const id = url.match(regex)?.groups?.id;
    if (id !== undefined) {
      return Number(id);
    }

    return undefined;
  }

  static currencyFormatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  static convertArray<T extends BaseType>(c: new () => T, data: unknown): T[] {
    const ret = new Array<T>();
    if (Array.isArray(data)) {
      data.forEach((element) => {
        const produkt = BaseType.createInstance(c);
        plainToClassFromExist(produkt, element, {
          excludeExtraneousValues: true,
        });

        if (produkt.uid.length > 0) ret.push(produkt);
        else
          catMain.error(
            "Cannot convert value!: " + JSON.stringify(element),
            null
          );
      });
    } else {
      throw new Error("Non-Array in convert method!");
    }
    return ret;
  }

  static convert<T extends BaseType>(c: new () => T, data: unknown): T | null {
    if (
      null === data ||
      undefined === data ||
      {} === data ||
      0 == Object.keys(data as Record<string, unknown>).length
    ) {
      catMain.debug("empty value -> return null");
      return null;
    }

    const produkt = BaseType.createInstance(c);
    try {
      plainToClassFromExist(produkt, data, { excludeExtraneousValues: true });

      if (produkt.uid.length > 0) return produkt;
    } catch (e) {
      catMain.error(
        "Caught error for data " + JSON.stringify(data) + ": " + e,
        null
      );
    }

    catMain.error("Cannot convert value!: " + JSON.stringify(data), null);

    return null;
  }
}
