import { Kopf } from "@/models/Rechnungen/Kopf";
import store from "@/store";
import { catMain } from "@/util/logging";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  //  dynamic: true,
  name: "vr",
  namespaced: true,
  store,
  //  preserveState: true,
})
export default class VerlagsRechnungModule extends VuexModule {
  //
  public rechnungen: Array<Kopf> = [];
  public aktuelleRechnung: Kopf | null = null;

  // === === === === === === === === === === === === === === === === === === === === ===

  @Mutation
  updateRechnung(kopf: Kopf | null): void {
    this.aktuelleRechnung = kopf;
    if (kopf === null) return;

    for (let i = 0; i < this.rechnungen.length; i++) {
      const k = this.rechnungen[i];
      if (k.uid === kopf.uid) {
        this.rechnungen[i] = k;
        catMain.info(`... updated Kopf at Position ${i}: ${k.uid}`);
        return;
      }
    }

    this.rechnungen.push(kopf);
  }

  @Mutation
  updateNummer(nummer: number): void {
    if (this.aktuelleRechnung === null) return;
    this.aktuelleRechnung.nummer = nummer;
  }

  @Mutation
  updateDatum(datum: string): void {
    if (this.aktuelleRechnung === null) return;
    this.aktuelleRechnung.datum = datum;
  }

  @Mutation
  removeRechnung(kopf: Kopf): void {
    catMain.info(`removing ${kopf?.uid}`);
    for (let i = 0; i < this.rechnungen.length; i++) {
      const k = this.rechnungen[i];
      if (k.uid === kopf.uid) {
        this.rechnungen.splice(i, 1);
        catMain.info(`... updated Kopf at Position ${i}: ${k.uid}`);

        if (this.aktuelleRechnung?.uid === kopf.uid) {
          catMain.info(`removed Kopf was active Kopf: ${kopf?.uid}`);
          if (this.rechnungen.length > 0)
            this.aktuelleRechnung = this.rechnungen[0];
          else this.aktuelleRechnung = null;
        }
        return;
      }
    }
  }

  // === === === === === === === === === === === === === === === === === === === === ===

  @Action({ rawError: true })
  async example(): Promise<void> {
    this.context.commit("changeAppLoadingState", true, { root: true });
  }
}
