import "vuetify/dist/vuetify.min.css";

import {
  faBars,
  faScanner as faScanner1,
} from "@fortawesome/pro-light-svg-icons";
import {
  faFileInvoiceDollar,
  faLightEmergencyOn,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faScanner as faScanner2,
  faUserSecret,
} from "@fortawesome/pro-duotone-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";
import Vuetify from "vuetify";
import colors from "vuetify/es5/util/colors";
import { faVuejs } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

Vue.component("font-awesome-icon", FontAwesomeIcon); // Register component globally
library.add(
  faVuejs as never,
  faBars as never,
  faUserSecret as never,
  faScanner1 as never,
  faScanner2 as never,
  faFileInvoiceDollar as never,
  faLightEmergencyOn as never
);

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.indigo.base,
        secondary: colors.teal.base,
        accent: colors.purple.base,
        error: colors.red.base,
        warning: colors.orange.base,
        info: colors.lightBlue.base,
        success: colors.green.base,
      },
    },
  },
  icons: {
    values: {
      // set menu to light (default is solid)
      invoice: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fa", "file-invoice-dollar"],
        },
      },
      menu: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fal", "bars"],
        },
      },
      // reusable custom icon
      vuejs: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fab", "vuejs"],
        },
      },
    },
  },
});
