import { catApi, catVue } from "@/util/logging";

import { BaseType } from "./util/BaseType";
import { Expose } from "class-transformer";
import { api } from "@/util/api";

export class FilialeModel extends BaseType {
  @Expose() uid = "";
  @Expose() url = "";
  @Expose({ name: "f_num" }) f_num = -1;

  @Expose({ name: "f_bez" }) f_bez = "";
  @Expose({ name: "f_kurz" }) f_kurz = "";
  @Expose({ name: "f_vn" }) f_vn = 0;

  @Expose({ name: "f_state" }) f_state = "";
  @Expose({ name: "f_sort" }) f_sort = 0;

  get nr(): number {
    return this.f_num;
  }
  get kurz(): string {
    return this.f_kurz;
  }
  get lang(): string {
    return this.f_bez;
  }
  get css(): string {
    return this.isAktiv() ? "filiale-aktiv" : "filiale-inaktiv";
  }

  public isValid(): boolean {
    return this.f_bez.length > 0 && this.f_num >= 0;
  }

  public isAktiv(): boolean {
    return this.f_state == "DIREKT" || this.f_state == "GROSSO";
  }

  public isAlt(): boolean {
    return !this.isAktiv();
  }

  // === === === === === === === === === === === === === === ===

  static loaded = (): boolean => {
    return filialenAlle.length > 0;
  };

  static clear = (): void => {
    filialenMap.clear();
    filialenAlt.clear();
    filialenAlle = [];
    filialenAktiv = [];
  };

  private static fetching = false;
  static fetch = async (): Promise<FilialeModel[]> => {
    catApi.debug("fetch ... querying: ");
    if (filialenAlle.length > 0) {
      catVue.error("Fetching Filialen when there are some set", null);
      //            FilialeModel.clear()
      return filialenAlle;
    }

    if (FilialeModel.fetching) return [];
    try {
      FilialeModel.fetching = true;
      const path = `v1/filialen`;
      const response = await api.get<FilialeModel[]>(path);
      catApi.debug(`response: /${path} -> ${JSON.stringify(response)}`);

      const ret = BaseType.convertArray(FilialeModel, response.data);

      if (!Array.isArray(ret)) {
        catVue.error("Should have gotten a List, got nothing!", null);
        throw new Error("filialenlist no list");
      }

      ret.forEach((fil) => {
        filialenMap.set(fil.f_num, fil);
        filialenAlle.push(fil);
        if (fil.isAktiv()) filialenAktiv.push(fil);
        else filialenAlt.add(fil.f_num);
      });
      FilialeModel.fetching = false;
      return ret;
      //
    } catch (e) {
      FilialeModel.fetching = false;
      const err = new Error(JSON.stringify(e));
      catApi.error("caught Error: " + err.message, err);
      return Promise.reject(err);
    }
  };

  // === === === === === === === === === === === === === === ===

  static get(num: number): FilialeModel | undefined {
    const fil = filialenMap.get(num);
    if (undefined === fil) {
      if (filialenMap.size == 0) {
        catVue.info("Reloading Filialen");
        this.fetch();
      } else {
        catVue.error(`Keine Filiale in der Liste zu Nummer ${num} ...`, null);
      }
      return undefined;
    }

    return fil;
  }

  static isAktiv(num: number): boolean {
    if (!this.loaded()) {
      catVue.info("filialenMap empty?");
      return false;
    }
    const fil = filialenMap.get(num);
    if (undefined === fil) {
      catVue.error(
        `isAktiv: Keine Filiale in der Liste zu Nummer ${num} ... ${filialenMap.size}`,
        null
      );
      return false;
    }

    return fil.isAktiv();
  }

  static filialenAlle(): Array<FilialeModel> {
    return filialenAlle;
  }
  static filialenAktiv(): Array<FilialeModel> {
    return filialenAktiv;
  }
}

const filialenMap: Map<number, FilialeModel> = new Map();
const filialenAlt: Set<number> = new Set();
let filialenAlle: Array<FilialeModel> = [];
let filialenAktiv: Array<FilialeModel> = [];
