import * as http from 'http';
import * as https from 'https';

const apiConfig = {

    returnRejectedPromiseOnError: true,
    withCredentials: true,
    timeout: 90000,
    baseURL: process.env.VUE_APP_API_ENDPOINT + "/",
    headers: {
        common: {
            "Cache-Control": "no-cache, no-store, must-revalidate",
            Pragma: "no-cache",
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Application": "TS-metheus " + process.env.VUE_APP_VERSION + " (" + process.env.VUE_APP_GIT_HASH + ")",
        },
        "X-USER-TOKEN": ""
    },
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),
    validateStatus: function (status: number): boolean {
        if (status >= 200 && status <= 304) return true; // default
        // console.log("Status: " + status)
        switch (status) {
            case 404:
                return true

            default:
                return false
        }
    },
}

export default apiConfig;