import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { catApi, catAuth } from "@/util/logging";

import apiConfig from "./api.config";
import { differenceInMilliseconds } from "date-fns";
import router from "@/router";
import store from "@/store";

//  Concurrent Request Handling
//  -> https://medium.com/@matthew_1129/axios-js-maximum-concurrent-requests-b15045eb69d0
//
const MAX_REQUESTS_COUNT = 5;
const INTERVAL_MS = 25;
const WAITING_REQUESTS = new Set<NodeJS.Timeout>();
let PENDING_REQUESTS = 0;

export interface ErrorResponse {
  code: number;
  status: string;
}

export interface CreatedResponse {
  uid: string; // the UID for the created respource
  url: string; // the URL for the created respource
  type: string; // the type of created resource, just to be sure
}

interface Duration {
  start?: number;
  end?: number;
  duration?: number;
}

export class Api {
  private api: AxiosInstance;
  private cfg: AxiosRequestConfig;

  static _duration(config: AxiosRequestConfig): Duration {
    // eslint-disable-next-line
    let dur = (config as any).metadata as Duration;

    if (dur === undefined) {
      dur = {} as Duration;
      // eslint-disable-next-line
      (config as any).metadata = dur;
    }

    // console.log("… _dur ", dur);
    return dur;
  }

  //  Constructor
  //  Creates the axios object and adds the interceptors.

  public constructor(config: AxiosRequestConfig) {
    this.api = axios.create(config);
    this.cfg = config;
    catApi.info("Configured API with " + JSON.stringify(config));

    //  REQUEST INTERCEPTORS

    // this middleware is been called right before the http request is made.
    this.api.interceptors.request.use(
      (config) => {
        Api._duration(config).start = Date.now();
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // This middleware will ensure not clogging the server by waiting if there are too many concurrent requests
    this.api.interceptors.request.use(function (config) {
      // eslint-disable-next-line
      return new Promise((resolve, _reject) => {
        const interval = setInterval(() => {
          if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
            PENDING_REQUESTS++;
            WAITING_REQUESTS.delete(interval);
            //                    catApi.info("WAITING " + WAITING_REQUESTS.size + " ...")
            clearInterval(interval);
            resolve(config);
          }
        }, INTERVAL_MS);
        WAITING_REQUESTS.add(interval);
        //             catApi.info("WAITING " + WAITING_REQUESTS.size + " ...")
      });
    });

    //  RESPONSE INTERCEPTORS

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.api.interceptors.response.use(
      (response) => {
        const dur = Api._duration(response.config);
        dur.end = Date.now();
        dur.duration =
          undefined != dur.start
            ? differenceInMilliseconds(dur.end, dur.start)
            : -1;

        // console.log("API: ", response)

        if (undefined === response || null === response) {
          // console.warn("Undefined Response", response)
          catAuth.info("Undefuned response in accept … redirecting to login");
          router.push({
            path: "/login",
            query: {
              ...router.currentRoute.query,
              redirect: router.currentRoute.fullPath,
            },
          });
          return Promise.reject(null);
        }
        return response;
      },
      (error) => {
        // console.log(error)
        catApi.info("Catching API Rejection ... " + error);
        catApi.info(error.response);
        catApi.info(error.response.status);

        if ("response" in error) {
          if (401 === error.response.status) {
            catAuth.info(
              "Seems we are not authorized … " +
                JSON.stringify(router.currentRoute, gcr())
            );
            if ("redirect" in router.currentRoute.query) {
              catApi.info("redirect already in query");
              router
                .push({
                  path: "/login",
                  query: { ...router.currentRoute.query },
                })
                .catch(() => {
                  /* intentionally left empty */
                });
            } else {
              catApi.info("first time redirect");
              router.push({
                path: "/login",
                query: {
                  ...router.currentRoute.query,
                  redirect: router.currentRoute.fullPath,
                },
              });
            }

            store.commit("setLastError", "Anmeldung erforderlich");
            return { code: 401, status: "401 Login first" };
          }

          if (400 === error.response.status) {
            catApi.info("seems like Client Error: " + error.response.data);
            return Promise.reject(error.response.data);
          }

          if (422 === error.response.status) {
            catApi.warn(
              "Unprocessable request intercepted ..." +
                error.response.config.method +
                " " +
                error.response.config.baseURL +
                error.response.config.url
            );
            store.commit(
              "setLastError",
              "422: " +
                error.response.config.method +
                " " +
                error.response.config.baseURL +
                error.response.config.url
            );
            return Promise.reject(error.response);
          }

          if (router.currentRoute.fullPath === "/buchscanner") {
            return Promise.reject(error.response);
          }

          catApi.info("Proceeding to default behaviour ... ");
        }

        if (true === error.isAxiosError) {
          catApi.warn("Axios Error … " + error);
          router.push({
            path: "/login",
            query: {
              ...router.currentRoute.query,
              redirect: router.currentRoute.fullPath,
            },
          });
          return { code: 422, status: "422 Axios Error" };
        } else {
          return Promise.reject(error);
        }
      }
    );

    this.api.interceptors.response.use((res) => {
      //      console.log(res.headers);
      if (res.headers !== undefined && "x-location" in res.headers) {
        catApi.info(`Location ... ${res.headers["x-location"]}`);
        store.commit("auth/setLocation", res.headers["x-location"]);
      }
      return res;
    });

    // this middleware decrements the concurrent requerst counter after the request completes (or errors out)
    this.api.interceptors.response.use(
      function (response) {
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        return Promise.resolve(response);
      },
      function (error) {
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        return Promise.reject(error);
      }
    );
  }

  static cancelAllWaiting(): void {
    WAITING_REQUESTS.forEach((interval) => {
      clearInterval(interval);
    });

    WAITING_REQUESTS.clear();
  }

  static countWaiting(): number {
    return WAITING_REQUESTS.size;
  }

  static allDone(): boolean {
    return WAITING_REQUESTS.size == 0 && PENDING_REQUESTS == 0;
  }

  public setToken(token: string): void {
    catAuth.info("New TOKEN := " + token);
    this.cfg.headers["X-USER-TOKEN"] = token;
  }

  public clearToken(): void {
    catAuth.info("CLEAR TOKEN");
    this.cfg.headers["X-USER-TOKEN"] = "";
  }

  public isErrorResponse(
    // eslint-disable-next-line
    candidate: any | ErrorResponse
  ): candidate is ErrorResponse {
    return (
      !this.isError(candidate) &&
      (candidate as ErrorResponse).code !== undefined
    );
  }

  // eslint-disable-next-line
  public isError(candidate: any): boolean {
    return null === candidate || undefined === candidate;
  }

  public getUri(config?: AxiosRequestConfig): string {
    return this.api.getUri({ ...this.cfg, ...config } as AxiosRequestConfig);
  }

  public request<T, R = AxiosResponse<T | ErrorResponse>>(
    config: AxiosRequestConfig
  ): Promise<R> {
    return this.api.request({ ...this.cfg, ...config } as AxiosRequestConfig);
  }

  public get<T, R = AxiosResponse<T | ErrorResponse>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.get(url, { ...this.cfg, ...config } as AxiosRequestConfig);
  }

  public delete<T, R = AxiosResponse<T | ErrorResponse>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.delete(url, {
      ...this.cfg,
      ...config,
    } as AxiosRequestConfig);
  }

  public head<T, R = AxiosResponse<T | ErrorResponse>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.head(url, { ...this.cfg, ...config } as AxiosRequestConfig);
  }

  public post<T, R = AxiosResponse<T | ErrorResponse>>(
    url: string,
    data: Record<string, unknown>,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.post(url, data, {
      ...this.cfg,
      ...config,
    } as AxiosRequestConfig);
  }

  public put<T, R = AxiosResponse<T | ErrorResponse>>(
    url: string,
    data?: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.put(url, data, {
      ...this.cfg,
      ...config,
    } as AxiosRequestConfig);
  }

  public patch<T, R = AxiosResponse<T | ErrorResponse>>(
    url: string,
    data?: string | Record<string, unknown>,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.patch(url, data, {
      ...this.cfg,
      ...config,
    } as AxiosRequestConfig);
  }

  public encodeComponent(str: string): string {
    return encodeURIComponent(str.replaceAll("/", "⧸")).replace(
      /[!'()*]/g,
      function (c) {
        return "%" + c.charCodeAt(0).toString(16);
      }
    );
  }
}

export const api = new Api(apiConfig);

// =============================================

const gcr = () => {
  const seen = new WeakSet();
  return (_key: unknown, value: unknown) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
