import { Module } from "vuex";

// import BreadCrumbItem from "@/classes/BreadcrumbItem";

// eslint-disable-next-line
const navigationModule: Module<any, any> = {

  state: {
    currentPosition: null,
    breadcrumb: []
  },

  getters: {},

  mutations: {
    // eslint-disable-next-line
    addLevelToNavigation(state: any, item: any /* BreadCrumbItem */) {
      if (state.currentPosition) {
        state.breadcrumb.push(state.currentPosition);
      }
      state.currentPosition = item;
    },

    // eslint-disable-next-line
    removeLevelFromNavigation(state: any) {
      state.currentPosition = state.breadcrumb.pop();
    },

    // eslint-disable-next-line
    clearNavigationState(state: any) {
      state.currentPosition = null;
      state.breadcrumb = [];
    }
  },

  actions: {}
};

export default navigationModule;
