import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import navigation from "./modules/navigation";
import AuthModule from "./modules/auth";
import VerlagsRechnungModule from "./modules/vr";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
});

export interface ChangePercentCompletePayload {
  current: number;
  total: number;
}

export default new Vuex.Store({
  strict: true,

  modules: {
    auth: AuthModule,
    vr: VerlagsRechnungModule,
    navigation,
  },

  state: () => {
    return {
      isAppLoading: false,
      percentComplete: "100%",
      lastError: "",
    };
  },

  mutations: {
    changeAppLoadingState(state, loading: boolean) {
      state.isAppLoading = loading;
      state.percentComplete = "";
    },
    changePercentComplete(state, payload: ChangePercentCompletePayload) {
      let current = payload.current;
      if (current < 0) current = payload.total + current;

      const pct = Math.min(
        100,
        Math.max(0, (current * 100.0) / (payload.total * 1.0 + 0.0000001))
      );
      state.percentComplete = pct.toFixed(1) + "%";

      if (pct < 50 && state.isAppLoading == false) state.isAppLoading = true;
    },
    setLastError(state, error: string) {
      state.lastError = error;
    },
  },

  actions: {},

  getters: {},

  plugins: [vuexLocal.plugin],
});
