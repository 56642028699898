import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { MandantModel, UserDataModel } from "@/models/UserDataModel";
import { catApi, catAuth } from "@/util/logging";

import { FilialeModel } from "@/models/FilialeModel";
import { api } from "@/util/api";

import router from "@/router";
import store from "@/store";

interface LoginMutationPayload {
  user: UserDataModel;
  mandant: MandantModel;
  filialMap: Map<number, FilialeModel>;
  filialLst: Array<FilialeModel>;
}

interface LoginActionPayload {
  user: string;
  pass: string;
}

@Module({ name: "auth", namespaced: true, store })
export default class AuthModule extends VuexModule {
  public loggedIn = false;
  public loginError: string | null = null;
  public user: UserDataModel | null = null;
  public mandant: MandantModel | null = null;

  public filialMap: Map<number, FilialeModel> | null = null;
  public filialLst: Array<FilialeModel> | null = null;
  public theFiliale: FilialeModel | null = null;

  public filialenAlle: Array<FilialeModel> | null = null;
  public filialenAktiv: Array<FilialeModel> | null = null;
  public filialenAlt: Array<boolean> = new Array<boolean>();

  public geoInfo = "0 0";
  public geoName: null | string = null;
  public apiVersion = "?";

  get humanLoginError(): string {
    return this.loginError || "";
  }

  get loggedInUser(): UserDataModel | string {
    return this.user || "";
  }

  @Mutation
  setFiliale(payload: FilialeModel): void {
    this.theFiliale = payload;
  }

  @Mutation
  setLocation(where: string | null): void {
    this.geoName = where;
  }

  @Mutation
  hasLoggedIn(payload: LoginMutationPayload): void {
    this.loggedIn = true;
    this.loginError = null;
    this.user = payload.user;
    this.mandant = payload.mandant;
    this.filialLst = payload.filialLst;
    this.filialMap = payload.filialMap;

    if (undefined !== this.user && "Filiale" in this.user) {
      const fnum = this.user.Filiale;
      const fili = this.filialMap.get(fnum);

      if (
        undefined !== fili &&
        null != fili &&
        (fili.f_state == "GROSSO" || fili.f_state == "DIREKT")
      ) {
        this.theFiliale = fili;
      } else if (this.filialLst !== undefined && this.filialLst.length > 0) {
        this.theFiliale = this.filialLst[0];
      } else {
        this.theFiliale = null;
      }
    }

    this.filialenAlle = [];
    this.filialenAktiv = [];
    this.filialenAlt = [];

    this.filialLst.forEach((f: FilialeModel) => {
      this.filialenAlle?.push(f);
      if (f.f_state == "GROSSO" || f.f_state == "DIREKT") {
        this.filialenAktiv?.push(f);
      } else {
        this.filialenAlt[f.f_num] = true;
      }
    });

    let redirectTo = "/";
    if (typeof router.currentRoute.query["redirect"] === "string")
      redirectTo = router.currentRoute.query["redirect"];

    if (redirectTo == "" || redirectTo.startsWith("/login")) redirectTo = "/";

    catAuth.info("hasLoggedIn. push to " + redirectTo);
    router.push(redirectTo);
  }

  @Mutation
  hasLoggedOut(): void {
    this.loggedIn = false;
    this.loginError = null;
    this.user = null;
    this.mandant = null;
    this.filialLst = null;
    this.filialMap = null;

    this.filialenAlle = null;
    this.filialenAktiv = null;
    this.filialenAlt = [];

    FilialeModel.clear();

    if (router.currentRoute.name !== "Login") router.push("/login");
  }

  @Mutation
  newApiVersion(ver: string): void {
    catApi.info(`(Re-)setting API Version ${this.apiVersion} -> ${ver}`);
    this.apiVersion = ver;
  }

  @Mutation
  newLocation(location: string): void {
    catApi.info(
      `(Re-)setting Location Version ${this.apiVersion} -> ${location}`
    );
    if (location !== this.geoInfo) {
      this.geoInfo = location;
      this.geoName = null;
    }
  }

  @Mutation
  //eslint-disable-next-line
  hasLoginError(state: any, payload: any): void {
    state.loginError = payload;
  }

  @Action({ rawError: true })
  async login(payload: LoginActionPayload): Promise<void> {
    catAuth.info(`LOGIN action .. ${JSON.stringify(payload, undefined, 2)}`);
    this.context.commit("changeAppLoadingState", true, { root: true });

    // const formData = new FormData();
    // formData.append("username", payload.name);
    // formData.append("password", payload.pwd);

    try {
      const response = await api.post<UserDataModel>("session", {
        username: payload.user,
        password: payload.pass,
        location: this.geoInfo,
      });

      catAuth.info("Logged In?");
      if (api.isErrorResponse(response.data)) {
        catAuth.info("Nope! Code:= " + response.data.code);
        throw response.data;
      }

      const user = new UserDataModel(response.data);
      const mandant = new MandantModel(response.data);

      catAuth.info("TOKEN => " + user.TOKEN);
      api.setToken(user.TOKEN);

      //  Get Filialen
      //
      const filialenResponse = await FilialeModel.fetch();
      const filialMap = new Map<number, FilialeModel>();
      const filialLst = new Array<FilialeModel>();
      for (const ff of filialenResponse) {
        filialMap.set(ff.f_num, ff);
        filialLst.push(ff);
      }

      if (filialMap.size < 1) throw "Kann Filialen nicht laden!";
      if (filialMap.size !== filialLst.length)
        throw "Filialen List vs Map Unterschied!";
      catAuth.info(JSON.stringify(filialLst));

      //  Has Logged in
      //
      this.context.commit("hasLoggedIn", {
        user: user,
        mandant: mandant,
        filialMap: filialMap,
        filialLst: filialLst,
      });

      //  Reset Loading State
      //
      this.context.commit("changeAppLoadingState", false, { root: true });
    } catch (error) {
      catAuth.error("ERROR Logging in: " + error, null);
      this.context.commit("hasLoggedOut");

      //     this.context.commit("loginError", error.response.data || "GENERAL_ERROR");
      this.context.commit("changeAppLoadingState", false, { root: true });
    }
  }

  @Action({ rawError: true })
  async locate(): Promise<void> {
    catAuth.info(`LOCATE action .. `);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          catAuth.info(
            `         geo .. ${JSON.stringify(position, undefined, 2)}`
          );
          catAuth.info(`         geo .. ${this.geoInfo}`);
          this.context.commit(
            "newLocation",
            "" +
              position.coords.latitude.toFixed(5) +
              " " +
              position.coords.longitude.toFixed(5)
          );
          catAuth.info(`         geo .. ${this.geoInfo}`);
        }
      );
    } else {
      this.context.commit("newLocation", "no_geoLocation");
    }
  }

  @Action({ rawError: true })
  async validate(): Promise<void> {
    api
      .patch<string>("session", {
        location: this.geoInfo,
      })
      .then((response) => {
        if (undefined === response) {
          catAuth.info("undefined response?");
        } else {
          catAuth.info("Validated!");
          if (!FilialeModel.loaded()) {
            FilialeModel.fetch();
          }

          catApi.debug(response.headers);
          const apiVersion = response.headers["p5-api-version"];
          if (apiVersion !== undefined && this.apiVersion != apiVersion) {
            this.context.commit("newApiVersion", apiVersion);
          }
          return;
        }
      })
      .catch((error) => {
        catAuth.error("ERROR Validating: ", error);
        catAuth.info("Logging Out!");
        api.clearToken();

        //    this.context.commit("clearNavigationState");
        this.context.commit("hasLoggedOut");
        this.context.commit("changeAppLoadingState", false, { root: true });
      });
  }

  @Action({ rawError: true })
  async logout(): Promise<void> {
    this.context.commit("changeAppLoadingState", true, { root: true });

    api
      .delete<string>("session")
      .then((response) => {
        if (undefined === response) {
          catAuth.info("undefined response?");
        } else {
          catAuth.info("Logged In?");

          if (api.isErrorResponse(response.data)) {
            catAuth.info("Nope! Code:= " + response.data.code);
          } else {
            catAuth.info("Response -> " + response.data);
          }
        }

        catAuth.info("Logging Out!");
        api.clearToken();

        //    this.context.commit("clearNavigationState");
        this.context.commit("hasLoggedOut");
        this.context.commit("changeAppLoadingState", false, { root: true });
      })
      .catch((error) => {
        catAuth.error("ERROR Logging out: ", error);
        catAuth.info("Logging Out!");
        api.clearToken();

        //    this.context.commit("clearNavigationState");
        this.context.commit("hasLoggedOut");
        this.context.commit("changeAppLoadingState", false, { root: true });
      });
  }
}
