import { Category, CategoryConfiguration, CategoryServiceControlSettings, CategoryServiceFactory, LogLevel, getCategoryControl, help } from "typescript-logging";

// Optionally change default settings, in this example set default logging to Info.
// Without changing configuration, categories will log to Error.
if (process.env.NODE_ENV == "development")
   CategoryServiceFactory.setDefaultConfiguration(new CategoryConfiguration(LogLevel.Info));

// Create categories, they will autoregister themselves, one category without parent (root) and a child category.
export const catMain = new Category("main");
export const catAuth = new Category("auth");
export const catNav = new Category("nav");
export const catApi = new Category("api");
export const catInput = new Category("input");
export const catVue = new Category("vue");
//export const catProd = new Category("product", catService);

// Force catMain to always be Info, even in Production
getCategoryControl().change({ category: catMain.id, logLevel: "Info" } as CategoryServiceControlSettings)

export const LOG = {
   help: help,
   getCategoryControl: getCategoryControl,
};
