import { Expose, plainToClassFromExist } from "class-transformer";

export enum PermissionType {
    PERM_BABU = 1 << 0,
    PERM_BABU_CREATE = 1 << 1,
    PERM_FEHLERLISTEN = 1 << 2,
    PERM_FEHLERLISTEN_SAVE = 1 << 3,
    PERM_REMI = 1 << 4,
    PERM_BUCH_USERS = 1 << 5,
    PERM_BUCH_ADMINS = 1 << 6,
    PERM_BUCH_OLA = 1 << 7,
    PERM_SUPERUSER = 1 << 15,
    PERM_THOMAS = 1 << 31,
}

export class UserDataModel {

    @Expose() UID = '';
    @Expose() TOKEN = '';
    @Expose({ name: "login" }) Login = '';
    @Expose({ name: "name" }) Name = '';
    @Expose({ name: "mandant" }) Mandant = 0;
    @Expose({ name: "filiale" }) Filiale = 0;
    @Expose({ name: "permissions" }) Permissions = 0;

    // eslint-disable-next-line
    constructor(plain: Record<string, any>) {
        // console.log("Creating …");
        plainToClassFromExist(this, plain, { excludeExtraneousValues: true });
        // console.log("Created: "+JSON.stringify(this, undefined, 2));
    }
}

export class MandantModel {

    @Expose({ name: "mandant" }) Mandant = 0;
    @Expose({ name: "Firma" }) Name = '';
    @Expose() EMail = '';
    @Expose() VDZ = 0;
    @Expose() features = '';

    // eslint-disable-next-line 
    constructor(plain: Record<string, any>) {
        // console.log("Creating …");
        plainToClassFromExist(this, plain, { excludeExtraneousValues: true });
        // console.log("Created: "+JSON.stringify(this, undefined, 2));
    }

    public get getVCA(): number {

        if (!this.features.includes(':VCA=')) return 0;
        return 1;
    }
}
