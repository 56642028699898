/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
function fixCircularReferences() {
  const defs = {} as Record<any, any>;
  return (k: any, v: any) => {
    let _v = "";
    if (v === null || v === undefined || typeof v != "object") {
      //      console.log(v);
      return v;
    }
    try {
      _v = v.toString();
    } catch (e) {
      //      console.log(v);
      _v = JSON.stringify(v);
      //      console.log(_v);
    }

    const def = defs[_v];
    if (def && typeof v == "object")
      return "[" + k + " is the same as '" + def + "']";
    defs[_v] = k;
    return v;
  };
}

export default class MyJSON {
  static out(object: any): string {
    return JSON.stringify(object, fixCircularReferences(), 2);
  }
}
